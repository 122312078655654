<template>
  <div>
    <ContentListTable></ContentListTable>
  </div>
</template>

<script>
import ContentListTable from '@/components/Admin/Content/ContentListTable'

export default {
  name: 'ContentList',
  components: { ContentListTable },
}
</script>

<style scoped></style>
