<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col
          cols="12"
          sm="6"
          class="text-uppercase display-2 font-weight-black"
        >
          Sub Content List
        </v-col>
      </v-row>
      <BaseLoading :loading="loading" />
      <v-dialog v-model="dialog1" persistent>
        <BaseLoading :loading="loading" />
        <v-card>
          <v-card-title>
            <span class="headline">{{ activeItem }}</span>
          </v-card-title>

          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12" sm="8">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body TH
                    </div>
                    <v-textarea
                      v-model="content_list.body_th"
                      label="Body TH"
                      hint="Hint text"
                      rows="2"
                      :rules="textAreaRules"
                      append-icon="mdi-comment"
                      required
                      counter
                      maxLength="500"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Body Eng
                    </div>
                    <v-textarea
                      v-model="content_list.body_en"
                      label="Body Eng"
                      hint="Hint text"
                      rows="2"
                      :rules="textAreaRules"
                      append-icon="mdi-comment"
                      required
                      counter
                      maxLength="500"
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title TH
                    </div>
                    <v-text-field
                      v-model="content_list.title_th"
                      label="Title TH"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="text-capitalize headline mb-5">
                      <span class="red--text">*</span>
                      Title Eng
                    </div>
                    <v-text-field
                      v-model="content_list.title_en"
                      label="Title Eng"
                      outlined
                      :rules="bulletTitleRules"
                      required
                      maxLength="150"
                      counter
                    ></v-text-field>
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="saveData()"
              :disabled="!valid"
              v-if="this.modal == 'new'"
            >
              Save
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="save()"
              :disabled="!valid"
              v-if="this.modal == 'edit'"
            >
              Save Edit
            </v-btn>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-capitalize display-1 my-5">Content List</div>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        hide-details
      ></v-text-field>
      <v-data-table
        dark
        fixed-header
        height="500"
        :headers="headers"
        :items="content_lists"
        :items-per-page="pageSize"
        :page.sync="page"
        @page-count="pageCount = $event"
        :search="search"
        :sort-desc="['id']"
        hide-default-footer
        class="elevation-1 my-5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
      </v-data-table>
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </v-container>
  </div>
</template>

<script>
import ConntentsServices from '@/services/ConntentsServices'
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data() {
    return {
      headers: [
        {
          text: 'Title_th',
          value: 'title_th',
          align: 'start',
          width: '40%',
        },
        {
          text: 'Title_en',
          value: 'title_en',
          align: 'start',
          width: '40%',
        },
        {
          text: 'Content_id',
          value: 'content_id',
          align: 'start',
          width: '1%',
        },
        { text: 'Actions', value: 'actions', align: 'end' },
      ],
    }
  },
  created() {
    this.readData()
  },
  methods: {
    readData() {
      ConntentsServices.getContent_List()
        .then(res => {
          if (res.status === 200) {
            this.content_lists = []
            this.content_lists = res.data
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.response.data.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        ConntentsServices.createContent_List({
          titleTH: this.content_list.title_th,
          titleEN: this.content_list.title_en,
          bodyTH: this.content_list.body_th,
          bodyEN: this.content_list.body_en,
          contentId: parseInt(this.content_list.content_id),
        })
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'Content List created successfully',
            })
            this.loading = !this.loading
            this.dialog1 = false
            this.resetData()
            this.readData()
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.response.data.message}`,
            })
            this.loading = !this.loading
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    resetData() {
      this.content_list = {
        title_th: '',
        title_en: '',
        body_th: '',
        body_en: '',
        content_id: null,
      }
    },
    addnew() {
      this.modal = 'new'
      this.activeItem = 'New Content List'
      this.resetData()
      this.dialog1 = true
    },
    editItem(contentList) {
      this.modal = 'edit'
      this.activeItem = 'Edit Content List'
      this.content_list = contentList
      this.dialog1 = true
    },
    deleteItem(doc) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            ConntentsServices.deleteContent_List(doc.id)
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Content List delete in successfully',
                })
                this.loading = !this.loading
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
    save() {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, edit it!',
        })
        .then(result => {
          if (result.value) {
            this.loading = !this.loading
            ConntentsServices.updateContent_List(this.content_list.id, {
              titleTH: this.content_list.title_th,
              titleEN: this.content_list.title_en,
              bodyTH: this.content_list.body_th,
              bodyEN: this.content_list.body_en,
              contentId: parseInt(this.content_list.content_id),
            })
              .then(() => {
                this.dialog1 = false
                this.$swal.fire({
                  icon: 'success',
                  title: 'Content List edited successfully',
                })
                this.loading = !this.loading
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.response.data.message}`,
                })
                this.loading = !this.loading
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
  },
}
</script>

<style></style>
